import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { RoleTag } from "@dewo/app/components/tags/RoleTag";
import { Role, User } from "@dewo/app/graphql/types";
import { Alert, Row, Tag, Typography } from "antd";
import React, { FC } from "react";

interface Props {
  users: User[];
  roles: Role[];
}

export const ParentWorkspacePermissionAlert: FC<Props> = ({ users, roles }) => (
  <Alert
    style={{ marginTop: 8 }}
    message={
      <>
        <Typography.Paragraph style={{ marginBottom: 8 }}>
          The following also have this permission because they have the
          permission in any parent spaces:
        </Typography.Paragraph>
        <Row>
          {roles.map((role) => (
            <RoleTag key={role.id} role={role} />
          ))}
          {users.map((user) => (
            <Tag
              key={user.id}
              icon={
                <UserAvatar size={16} user={user} style={{ marginRight: 8 }} />
              }
            >
              {user.username}
            </Tag>
          ))}
        </Row>
      </>
    }
  />
);
