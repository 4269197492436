import { UserSelect } from "@dewo/app/components/form/UserSelect";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { RoleWithRules, RulePermission, User } from "@dewo/app/graphql/types";
import { useRunning, useRunningCallback } from "@dewo/app/util/hooks";
import { Button, Form, message, Row, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useOrganizationUsers } from "../organization/hooks";
import { useUserRoles } from "../user/hooks";
import { useSyncRules } from "./hooks";
import { hasRule, RuleContext } from "./util";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { RoleSelect } from "@dewo/app/components/form/RoleSelect";
import { ParentWorkspacePermissionAlert } from "./ParentWorkspacePermissionAlert";
import { UsergroupAddOutlined } from "@ant-design/icons";

interface FormValues {
  roleIds: string[];
  userIds: string[];
}

const defaultContext: RuleContext = {};

interface Props {
  context?: RuleContext;
  parentContexts?: RuleContext[];
  workspaceId?: string;
  fundingSessionId?: string;
  organizationId: string;
  roles: RoleWithRules[];
  permission: RulePermission;
  disallowEveryoneElse?: boolean;
  disabled?: boolean;
  saveButtonTooltip?: string;
  requiresCurrentUserToHaveRole?: boolean;
  onInviteUser?(): Promise<void>;
}

function useSubmitEnabled(
  values: FormValues,
  requiresCurrentUserToHaveRole: boolean
): boolean {
  const { user } = useAuthContext();
  const roles = useUserRoles(user?.id!)?.roles;
  const hasCurrentUserRole = useMemo(
    () =>
      values.userIds.includes(user?.id!) ||
      !!roles?.some((role) => values.roleIds.includes(role.id)),
    [values, user, roles]
  );

  return !requiresCurrentUserToHaveRole || hasCurrentUserRole;
}

export const RBACPermissionForm: FC<Props> = ({
  context = defaultContext,
  parentContexts,
  organizationId,
  roles,
  permission,
  disallowEveryoneElse,
  saveButtonTooltip,
  requiresCurrentUserToHaveRole = false,
  onInviteUser,
}) => {
  const hasPermission = usePermission("update", {
    __typename: "Rule",
    permission,
    ...context,
  });

  const { users } = useOrganizationUsers(organizationId);

  const organizationRolesWithParentAccess = useMemo(
    () =>
      roles?.filter(
        (r) =>
          !r.userId && !!parentContexts?.some((c) => hasRule(r, permission, c))
      ),
    [roles, parentContexts, permission]
  );
  const usersWithParentAccess = useMemo(
    () =>
      roles
        ?.filter(
          (r) =>
            !!r.userId &&
            !!parentContexts?.some((c) => hasRule(r, permission, c))
        )
        .map((r) => r.userId!)
        .map((userId) => users?.find((u) => u.id === userId))
        .filter((user): user is User => !!user),
    [roles, parentContexts, permission, users]
  );

  const organizationRoles = useMemo(
    () => roles?.filter((role) => !role.userId && !role.fallback),
    [roles]
  );

  const [form] = useForm<FormValues>();
  const initialValues = useMemo(
    () => ({
      roleIds: roles
        .filter((r) => hasRule(r, permission, context) && !r.userId)
        .map((r) => r.id),
      userIds: roles
        .filter((r) => hasRule(r, permission, context) && !!r.userId)
        .map((r) => r.userId!)
        .filter((userId, index, array) => array.indexOf(userId) === index),
    }),
    [roles, permission, context]
  );
  const [values, setValues] = useState<FormValues>(initialValues);
  const handleChange = useCallback(
    (_changed: Partial<FormValues>, values: FormValues) => setValues(values),
    []
  );

  const syncRules = useSyncRules();
  const [handleSave, saving] = useRunning(
    useCallback(
      async (values: FormValues) => {
        await syncRules({
          organizationId,
          permission,
          context,
          roleIds: values.roleIds,
          userIds: values.userIds,
          disallowEveryoneElse,
        });

        message.success("Permissions updated!");
      },
      [syncRules, context, permission, organizationId, disallowEveryoneElse]
    )
  );

  const [handleInviteUser, invitingUser] = useRunningCallback(
    () => onInviteUser?.(),
    [onInviteUser]
  );

  const dirty = useMemo(
    () =>
      !_.isEqual(_.sortBy(values.userIds), _.sortBy(initialValues.userIds)) ||
      !_.isEqual(_.sortBy(values.roleIds), _.sortBy(initialValues.roleIds)),
    [initialValues, values]
  );
  const buttonDisabled = !useSubmitEnabled(
    values,
    requiresCurrentUserToHaveRole
  );
  const button = (
    <Button
      type="primary"
      disabled={buttonDisabled}
      loading={saving}
      onClick={form.submit}
    >
      Save
    </Button>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
      style={{ overflow: "hidden" }}
      onValuesChange={handleChange}
      onFinish={handleSave}
    >
      {(hasPermission || !!values.roleIds.length) && (
        <Form.Item label="Roles" name="roleIds">
          <RoleSelect
            roles={organizationRoles}
            organizationId={organizationId}
            placeholder="Select Roles..."
            disabled={!hasPermission}
          />
        </Form.Item>
      )}
      {(hasPermission || !!values.userIds.length) && (
        <Row align="bottom" style={{ gap: 8 }}>
          <Form.Item label="Users" name="userIds" style={{ flex: 1 }}>
            <UserSelect
              mode="multiple"
              placeholder="Select Users..."
              disabled={!hasPermission}
              users={users}
              loading={!users}
            />
          </Form.Item>
          {hasPermission && !!onInviteUser && (
            <Button
              type="primary"
              loading={invitingUser}
              icon={<UsergroupAddOutlined />}
              style={{ marginBottom: 12 }}
              onClick={handleInviteUser}
            >
              Invite
            </Button>
          )}
        </Row>
      )}

      {hasPermission &&
        dirty &&
        (!!saveButtonTooltip ? (
          <Tooltip title={saveButtonTooltip} placement="bottom">
            {button}
          </Tooltip>
        ) : (
          button
        ))}

      {(!!organizationRolesWithParentAccess.length ||
        !!usersWithParentAccess.length) && (
        <ParentWorkspacePermissionAlert
          roles={organizationRolesWithParentAccess}
          users={usersWithParentAccess}
        />
      )}
    </Form>
  );
};
