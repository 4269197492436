import React, { FC, useCallback } from "react";
import { Button, ButtonProps } from "antd";
import { NotionIcon } from "@dewo/app/components/icons/Notion";
import { useConnectToNotion } from "./hooks";

interface Props extends ButtonProps {
  organizationId: string;
}

export const ConnectOrganizationToNotionButton: FC<Props> = ({
  organizationId,
  children = "Connect to Notion",
  icon = <NotionIcon />,
  ...buttonProps
}) => {
  const connect = useConnectToNotion();
  const handleClick = useCallback(
    () => connect(organizationId),
    [connect, organizationId]
  );
  return (
    <Button
      {...buttonProps}
      icon={icon}
      children={children}
      onClick={handleClick}
      name="Connect Notion integration"
    />
  );
};
